(function ($) {
  Drupal.behaviors.headerGnavcartV1 = {
    attach: function (context) {
      var $body = $('body');
      var $template = $('.js-header-gnav-cart-v1', context);
      var $cartContainer = $('.js-header-gnav-cart__overlay', $template);
      var $overlay = $body.find('.js-gnav-header-formatter__bg-full-width');
      var $closeIcon = $('.js-header-gnav-cart__close', $template);

      // @todo do we want to manage this generically in the gnav formatter?
      $closeIcon.add($overlay).once().on('click', function () {
        $cartContainer.removeClass('visible');
        $body.removeClass('active-gnav');
      });
    }
  };
})(jQuery);
